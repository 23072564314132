import React from 'react'
import {Link} from 'gatsby'
import notFound from '../assets/images/error.png'
import Navbar from "../components/App/Navbar"
import Layout from "../components/App/Layout"
import { Helmet } from 'react-helmet'


const NotFound = () => {
  return (
    <Layout>
        <Helmet>
          <title>Wispeek | 404, page non trouvée</title>
          <meta name="description" content="Oups! Quelque chose ne s’est pas passé comme prévu …  La page demandé n’edéa pas été trouvée. Retour à l’accueil." />
        </Helmet>
      <Navbar />
        <div className="ptb-100">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="center">
                        <div className="logo">
                            <img src={notFound} alt="error 404" />
                        </div>
                        <h2 className='ptb-30'>Erreur 404</h2>
                        <Link to="/">Retour à l'acceuil ?</Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default NotFound